import {
    PageTitle,
    TrackItem,
    TrackEventClick,
    TrackEventTimed,
    TrackOptions,
} from "@/types";
import { useRoute } from "vue-router";
import { useTrackingStore } from "@/stores/tracking";
import api from "@/api";
import { useMainStore } from "@/stores/main";

export default function useTracking() {
    const store = useTrackingStore();
    const mainStore = useMainStore();
    const route = useRoute();
    const page: PageTitle = route.name as string;

    const addTrackItem = (trackItem: TrackItem) => {
        if (mainStore.hasLoggedIn) {
            api.addTrackItem({
                ...trackItem,
                fe_version: mainStore.versionStringToNumber,
            });
        }
    };

    const startTimingEvent = (event: TrackEventTimed, id: number | string) => {
        store.startTimedEvent(event, id, page);
    };

    const stopTimingEvent = (
        event: TrackEventTimed,
        id: number | string,
        data: TrackOptions
    ) => {
        const timedEvent = store.getTimedEvent(event, id);
        if (timedEvent) {
            const timeSpent = new Date().getTime() - timedEvent.start;
            const trackItem = timedEvent.trackItem;
            trackItem.data = data;
            trackItem.data.timeSpent = timeSpent;
            addTrackItem(trackItem);
            store.removeTimedEvent(timedEvent);
        }
    };

    const clickEvent = (event: TrackEventClick, data: TrackOptions) => {
        const trackItem: TrackItem = {
            event,
            page,
            data,
        };
        addTrackItem(trackItem);
    };

    const enterPage = (data: TrackOptions) => {
        const trackItem: TrackItem = {
            event: "enter-page",
            page,
            data,
        };
        addTrackItem(trackItem);
    };

    const timeVideoUse = (
        element: HTMLElement,
        event: TrackEventTimed,
        id: string
    ) => {
        const iframe = element.getElementsByTagName("iframe")[0];

        const blurListener = () => {
            if (document.activeElement === iframe) {
                startTimingEvent(event, id);
            }
        };

        const focusListener = () => {
            if (document.activeElement !== iframe) {
                stopTimingEvent(event, id, {});
                cleanUpListeners();
            }
        };

        const cleanUpListeners = () => {
            window.removeEventListener("blur", blurListener);
            window.removeEventListener("focus", focusListener);
        };

        // start timing when the iframe is focused, which might be quite accurate
        // for starting the video. But blurring isnt perfect, since hitting the
        // pause button will not fire our stop event. But for cross-domain this
        // might be the best we have for now.
        // todo checkout if we dont make a mess with adding listeners
        window.addEventListener("blur", blurListener);
        window.addEventListener("focus", focusListener);
    };

    return {
        startTimingEvent,
        stopTimingEvent,
        clickEvent,
        enterPage,
        timeVideoUse,
    };
}
