import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-2fb972e8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ActivationSuccess" }
const _hoisted_2 = { class: "ActivationSuccess__confirm" }
const _hoisted_3 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_etd_suggestion = _resolveComponent("etd-suggestion")!
  const _component_etd_button = _resolveComponent("etd-button")!
  const _component_etd_container = _resolveComponent("etd-container")!
  const _component_activation_success_icon = _resolveComponent("activation-success-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.status === 'idle')
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          ref: "idleElement",
          style: _normalizeStyle({ 'min-height': _ctx.height + 'px' })
        }, [
          _createElementVNode("p", null, _toDisplayString(_ctx.$t("modules.activate.popup.body")), 1),
          _createVNode(_component_etd_container, { gap: "l" }, {
            default: _withCtx(() => [
              (!_ctx.store.access.has_own_exam)
                ? (_openBlock(), _createBlock(_component_etd_suggestion, {
                    key: 0,
                    id: "activate-suggestion",
                    "background-color": 
                        _ctx.themeBlue
                            ? 'var(--color-blue-4)'
                            : 'var(--color-suggestion)'
                    ,
                    color: 
                        _ctx.themeBlue ? 'var(--color-white)' : 'var(--color-black)'
                    
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.themeBlue ? 'strong' : 'div'), null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t("modules.activate.popup.info")), 1)
                        ]),
                        _: 1
                      }))
                    ]),
                    _: 1
                  }, 8, ["background-color", "color"]))
                : _createCommentVNode("", true),
              _createVNode(_component_etd_button, {
                class: "activate-button",
                onClick: _ctx.activate,
                size: "big",
                style: {"max-width":"360px"}
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("modules.activate.popup.activate")), 1)
                ]),
                _: 1
              }, 8, ["onClick"]),
              (_ctx.showDiscard)
                ? (_openBlock(), _createBlock(_component_etd_button, {
                    key: 1,
                    id: "activate-discard-button",
                    variant: "secondary",
                    size: "big",
                    onClick: _ctx.discardActivatePopup
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("modules.activate.popup.later")), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ], 4))
      : _createCommentVNode("", true),
    (_ctx.status === 'loading' || _ctx.status === 'success')
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          ref: "loadingElement",
          class: "ActivationSuccess__loading",
          style: _normalizeStyle({ 'min-height': _ctx.height + 'px' })
        }, [
          _createVNode(_component_activation_success_icon, {
            success: _ctx.status === 'success'
          }, null, 8, ["success"]),
          _createElementVNode("div", _hoisted_2, [
            (_ctx.status === 'success')
              ? (_openBlock(), _createBlock(_component_etd_button, {
                  key: 0,
                  onClick: _ctx.applySuccess,
                  id: "apply-activate"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("modules.activate.popup.applySuccess")), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"]))
              : _createCommentVNode("", true)
          ])
        ], 4))
      : _createCommentVNode("", true),
    (_ctx.status === 'error')
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, "error"))
      : _createCommentVNode("", true)
  ]))
}