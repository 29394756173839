import { createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_etd_popup = _resolveComponent("etd-popup")!

  return (_openBlock(), _createBlock(_component_etd_popup, {
    "close-button": "",
    onClose: _ctx.close
  }, {
    title: _withCtx(() => [
      _createTextVNode(" ")
    ]),
    body: _withCtx(() => [
      _createElementVNode("div", {
        class: "Banner",
        innerHTML: _ctx.content
      }, null, 8, _hoisted_1)
    ]),
    _: 1
  }, 8, ["onClose"]))
}