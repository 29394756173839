<script setup lang="ts">
import { computed, provide, onMounted } from "vue";
import { useMainStore } from "@/stores/main";
import useMediaQuery from "@innouveau/123-etd/dist/composables/useMediaQuery.min.mjs";
import ActivateBanner from "@/components/elements/activate/ActivateBanner.vue";
import ActivatePopup from "@/components/elements/activate/ActivatePopup.vue";
import Banner from "@/components/elements/banner/Banner.vue";
import useApplicationUse from "@/composables/useApplicationUse";
import AccessLayer from "@/components/elements/access-layer/AccessLayer.vue";
import HelpdeskButton from "@/components/elements/buttons/HelpdeskButton.vue";

const store = useMainStore();

const { initApplicationUse } = useApplicationUse();

const { mq } = useMediaQuery();
provide("mq", mq);

const showActivateBanner = computed(() => {
    return store.hasLoggedIn && store.access.video === "inactive";
});

const showActivatePopup = computed(() => {
    return (
        store.hasLoggedIn &&
        store.access.video === "inactive" &&
        !store.discardedActivatePopup
    );
});

const hasBanner = computed(() => window.config.banner.active);
const bannerContent = computed(() =>
    hasBanner.value ? window.config.banner.content : ""
);
const closeBanner = () => {
    store.bannerActive = false;
};

const setVersion = async () => {
    const response = await fetch("/version.json");
    const json = await response.json();
    store.version = json.version;
};

onMounted(async () => {
    await setVersion();
    initApplicationUse();
});
</script>

<template>
    <div class="App" :data-mq="mq.t" :data-has-banner="showActivateBanner">
        <activate-banner v-if="showActivateBanner" />
        <activate-popup v-if="showActivatePopup" />

        <div class="App__view">
            <access-layer>
                <etd-error-boundary>
                    <template #content>
                        <router-view />
                    </template>

                    <template #footer="{ atClose }">
                        <helpdesk-button @click="atClose" />
                    </template>
                </etd-error-boundary>
            </access-layer>
        </div>

        <banner
            v-if="hasBanner && store.bannerActive"
            @close-banner="closeBanner"
            :content="bannerContent"
        />
    </div>
</template>

<style lang="scss">
@import "@innouveau/123-etd/dist/index.css";

* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a {
    text-decoration: none;
}

.App {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    --banner-height: 60px;

    &__view {
        height: 100%;
        position: relative;
        overflow: auto;

        .ErrorBoundary {
            height: 100%;
        }
    }

    &[data-has-banner="true"] {
        .App__view {
            height: calc(100% - var(--banner-height));
        }
    }
}
</style>
